import './App.css';
import Routes from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (
    <Router>
      {/* <ScrollToTop /> */}
      <Routes />
    </Router>
  );
}

export default App;
