import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { Pagination, Autoplay } from 'swiper/modules';
import { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import axios from 'axios';

const Home = () => {
  const [swiper, setSwiper] = useState(null);
  const [stripeLink, setStripeLink] = useState('');

  useEffect(() => {
    const createCheckoutSession = async () => {
      try {
        const response = await axios.post(
          'https://cors-anywhere-proxy-18sa.onrender.com/create-checkout-session'
        );

        console.log('Checkout Session ID:', response.data.id);
        setStripeLink(response.data.id);

        return response.data.id;
      } catch (error) {
        console.error('Error creating Checkout Session:', error);
        throw new Error('Error creating Checkout Session');
      }
    };

    createCheckoutSession();
  }, []);

  const goToCheckout = () => {
    window.location.href(stripeLink);
  };

  return (
    <div>
      <main
        id='MainContent'
        className='content-for-layout focus-none'
        role='main'
        tabIndex={-1}>
        <div
          id='shopify-section-template--22099309691189__instant-lohlvIOs0uGO9XiV'
          className='shopify-section'>
          <div
            className='__instant'
            data-instant-id='lohlvIOs0uGO9XiV'
            data-instant-version='2.1.8'
            data-section-id='template--22099309691189__instant-lohlvIOs0uGO9XiV'>
            <link
              href='//cosori.com/cdn/shop/t/6/assets/instant-lohlvIOs0uGO9XiV.css?v=129925537045948844891710265678'
              rel='stylesheet'
              type='text/css'
              media='all'
            />
            <link
              href='https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap'
              rel='stylesheet'
            />
            <style
              dangerouslySetInnerHTML={{
                __html:
                  "\n    @font-face {\n      font-family: 'Sun Valley Regular Webfont';\n      src: url('https://assets.instant.so/sites/dumEsNefpCLLZuwN/assets/MmOCU64140h1tA8k/sunvalley-webfont.woff2')\n        format('woff2');\n      font-weight: 400;\n      font-display: swap;\n    }\n  ",
              }}
            />
            <div data-instant-type='root' className='iRERqEnG0tRdQmqZQ'>
              <div
                className='ixDvz3snrkvrNSKpl'
                data-instant-type='container'
                id='ixDvz3snrkvrNSKpl'>
                <div
                  className='ijegh2gU2NkVbG7XI'
                  data-instant-type='container'>
                  <div
                    className='ij7t8RBIY2wryw2mr'
                    data-instant-type='container'>
                    <span
                      data-instant-type='text'
                      className='instant-rich-text ixuFI5B22wkMjT6N7'>
                      <span>2-YEAR WARRANTY</span>
                    </span>
                    <span
                      data-instant-type='text'
                      className='instant-rich-text iCCjZ5Rhdl5gIuY3J'>
                      <span>
                        Get the TurboBlaze™ with <strong>20% OFF</strong>
                      </span>
                    </span>
                    <span
                      data-instant-type='text'
                      className='instant-rich-text ib6HgZfKEF8SzRpDY'>
                      <span>FREE SHIPPING INCLUDED</span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className='iztSXXpdblgZRLxwc'
                data-instant-type='container'
                id='iztSXXpdblgZRLxwc'>
                <div
                  className='iAKrFdTqQOm1QQcYc'
                  data-instant-type='container'
                  data-instant-form-product-url='/products/turboblaze-air-fryer'
                  data-instant-form-variant-id='gid://shopify/ProductVariant/47075741335861'>
                  <div
                    className='i2kgR2gWi4aZTFnph'
                    data-instant-type='container'
                    href=''
                    rel='noopener noreferrer'>
                    <img
                      alt=''
                      src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/yTcfpTBSKJuwFBmF/cosori1.svg'
                      width={121}
                      height={22}
                      data-instant-type='image'
                      decoding='async'
                      loading='lazy'
                      className='isoVLFp3kKnJNIP9P'
                      data-uw-rm-alt-original=''
                    />
                    <h3
                      data-instant-type='text'
                      className='instant-rich-text i5QbneCMVP1xaGx5r'>
                      <span>TurboBlaze™ 6.0-Quart Air Fryer</span>
                    </h3>
                    <h2
                      data-instant-type='text'
                      className='instant-rich-text iVVuYk9SWKvjR5Xeh'>
                      <span>Experience The Evolution</span>
                    </h2>
                    <p
                      data-instant-type='text'
                      className='instant-rich-text itrD3nQ2oayRwFsyW'>
                      <span>
                        Blending Technology with Taste to Redefine Home Dining.
                      </span>
                    </p>
                    <span
                      data-instant-type='text'
                      className='instant-rich-text iJUdMtpaynATKkFNF'>
                      <span>Limited Time Offer!</span>
                    </span>
                    <div
                      className='iVtQ6tLmHxo3MfLjl'
                      data-instant-type='container'>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text iqikW0UWe96Kcvk3a'>
                        <span>$96</span>
                      </span>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text iFvNEXtyAeuIbaZq3'>
                        <span>$119.99</span>
                      </span>
                    </div>
                    <a
                      className='iqfMml7Rh2mmBXsGA'
                      data-instant-type='container'
                      href={stripeLink}
                      rel='noopener noreferrer'>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text iuzG3lvIqO8jZiwML'>
                        <span>Shop TurboBlaze • 20% Off</span>
                      </span>
                    </a>
                    <span
                      data-instant-type='text'
                      className='instant-rich-text iP4y79KUeterG3IjS'>
                      <span>
                        Get the TurboBlaze™ with <strong>FREE SHIPPING</strong>{' '}
                        — within 7 Business Days.
                      </span>
                    </span>
                  </div>
                  <img
                    alt=''
                    srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=3840 3840w'
                    src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg'
                    width={800}
                    height={797}
                    data-instant-type='image'
                    decoding='async'
                    loading='lazy'
                    className='ija7vamfRFy75WuJb'
                    data-uw-rm-alt-original=''
                  />
                </div>
              </div>
              <div
                className='i40prx0zT4SbAlI1X'
                data-instant-type='container'
                id='i40prx0zT4SbAlI1X'>
                <div
                  className='i55rt08CaJQPeVMHZ'
                  data-instant-type='container'>
                  <div
                    className='iCOjIhhrNSl86W7M5'
                    data-instant-type='container'>
                    <img
                      alt=''
                      src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/yTcfpTBSKJuwFBmF/cosori1.svg'
                      width={121}
                      height={22}
                      data-instant-type='image'
                      decoding='async'
                      loading='lazy'
                      className='iE6mItLnM45YoVxMG'
                      data-uw-rm-alt-original=''
                    />
                    <h3
                      data-instant-type='text'
                      className='instant-rich-text iazWgfIXroJXrFQ1m'>
                      <span>TurboBlaze™ 6.0-Quart Air Fryer</span>
                    </h3>
                    <h2
                      data-instant-type='text'
                      className='instant-rich-text iOOiTsPIUfScsyvSF'>
                      <span>Experience The Evolution</span>
                    </h2>
                    <p
                      data-instant-type='text'
                      className='instant-rich-text iVyabxpAFGlgniuMF'>
                      <span>
                        Blending Technology with Taste to Redefine Home Dining.
                      </span>
                    </p>
                    <span
                      data-instant-type='text'
                      className='instant-rich-text isX1QpTE9j56YsQAF'>
                      <span>Limited Time Offer!</span>
                    </span>
                    <div
                      className='igUXhOsOxqEI1A36q'
                      data-instant-type='container'>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text ic8KdVBqgY4I5UHbc'>
                        <span>$96</span>
                      </span>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text iFAmsGO8yXarw9w9W'>
                        <span>$119.99</span>
                      </span>
                    </div>
                    <div
                      className='iQxBSDY3Vfi3MYIMO'
                      data-instant-type='container'
                      data-instant-form-product-url='/products/turboblaze-air-fryer'
                      data-instant-form-variant-id='gid://shopify/ProductVariant/47075741335861'
                      href={stripeLink}
                      rel='noopener noreferrer'>
                      <a
                        data-instant-type='text'
                        className='iAm6KJOC5n40I17Jn'
                        href=''
                        rel='noopener noreferrer'>
                        <span className='instant-rich-text'>
                          <span>Get TurboBlaze for Only $96</span>
                        </span>
                      </a>
                    </div>
                    <span
                      data-instant-type='text'
                      className='instant-rich-text ix5KRyTt9YZdIZLiW'>
                      <span>
                        Get the TurboBlaze™ with <strong>FREE SHIPPING</strong>{' '}
                        — within 7 Business Days.
                      </span>
                    </span>
                  </div>
                  <img
                    alt=''
                    srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=3840 3840w'
                    src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg'
                    width={800}
                    height={797}
                    data-instant-type='image'
                    decoding='async'
                    loading='lazy'
                    className='ik5K6STroX7gitpBv'
                    data-uw-rm-alt-original=''
                  />
                </div>
              </div>
              <div
                className='iBdnaZ5yh3bDutacx'
                data-instant-type='container'
                id='iBdnaZ5yh3bDutacx'>
                <div
                  className='iM2cBb6WmF3pzMMIK'
                  data-instant-type='container'>
                  <div
                    className='ixEuTScuU6GYK50qu'
                    data-instant-type='container'>
                    <div
                      className='iHgIUM62sdzcjUZaN'
                      data-instant-type='container'>
                      <img
                        alt=''
                        src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/yTcfpTBSKJuwFBmF/cosori1.svg'
                        width={121}
                        height={22}
                        data-instant-type='image'
                        decoding='async'
                        loading='lazy'
                        className='i0Z0o5pUoFQRoHaGZ'
                        data-uw-rm-alt-original=''
                      />
                      <h3
                        data-instant-type='text'
                        className='instant-rich-text igMw819xLpaKyrcXw'>
                        <span>TurboBlaze™ 6.0-Quart Air Fryer</span>
                      </h3>
                      <h2
                        data-instant-type='text'
                        className='instant-rich-text iSbIj7DLNlB0bx8v8'>
                        <span>Experience The Evolution</span>
                      </h2>
                      <p
                        data-instant-type='text'
                        className='instant-rich-text is4KLmWgYjXQb4kBr'>
                        <span>
                          Blending Technology with Taste to Redefine Home
                          Dining.
                        </span>
                      </p>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text iKp51XZKkU38cqNTg'>
                        <span>Limited Time Offer!</span>
                      </span>
                      <div
                        className='iCRxF1nwxef1xZySC'
                        data-instant-type='container'>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text i6CR2tkec1yMmIfBG'>
                          <span>$96</span>
                        </span>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text ibBPPSsmwXNi0PfBg'>
                          <span>$119.99</span>
                        </span>
                      </div>
                      <div
                        className='iRVHD2s1SJdUXZAQ0'
                        data-instant-type='container'
                        data-instant-form-product-url='/products/turboblaze-air-fryer'
                        data-instant-form-variant-id='gid://shopify/ProductVariant/47075741335861'
                        href={stripeLink}
                        rel='noopener noreferrer'>
                        <a
                          data-instant-type='text'
                          className='i4UqtU6zXSIvpYyJo'
                          href=''
                          rel='noopener noreferrer'>
                          <span className='instant-rich-text'>
                            <span>Get TurboBlaze for Only $96</span>
                          </span>
                        </a>
                      </div>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text iApyhVt5yYIWytkyg'>
                        <span>
                          Get the TurboBlaze™ with{' '}
                          <strong>FREE SHIPPING</strong> — within 7 Business
                          Days.
                        </span>
                      </span>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text irI0UroeNR75tnUOh'>
                        <span>
                          Free shipping on all orders. Hurry, while supplies
                          last!
                        </span>
                      </span>
                    </div>
                    <img
                      alt=''
                      srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg?width=3840 3840w'
                      src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/0vQkSgBZ6WfDpJAa/463b7475-34f4-447f-93a6-719f42ca4eb6.jpg'
                      width={800}
                      height={797}
                      data-instant-type='image'
                      decoding='async'
                      loading='lazy'
                      className='ihZy7eoy4qRSvhIhf'
                      data-uw-rm-alt-original=''
                    />
                  </div>
                </div>
              </div>
              <div
                className='i7cmMPB1kFaiEJ2za'
                data-instant-type='container'
                id='i7cmMPB1kFaiEJ2za'>
                <div
                  className='iP8OtzOK8LvFqbqSp'
                  data-instant-type='container'>
                  <div
                    className='itGydvB2m2uA1V0FK'
                    data-instant-type='container'>
                    <span
                      data-instant-type='text'
                      className='instant-rich-text i0e2onpaf2MNe1WV9'>
                      <span>AS SEEN ON</span>
                    </span>
                    <div
                      className='iIDaGgeH3PYHj9EjJ'
                      data-instant-type='container'>
                      <img
                        alt=''
                        srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/lZJbxi1cPcoXS7U1/as-seen-on-yahoo-finance.png?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/lZJbxi1cPcoXS7U1/as-seen-on-yahoo-finance.png?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/lZJbxi1cPcoXS7U1/as-seen-on-yahoo-finance.png?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/lZJbxi1cPcoXS7U1/as-seen-on-yahoo-finance.png?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/lZJbxi1cPcoXS7U1/as-seen-on-yahoo-finance.png?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/lZJbxi1cPcoXS7U1/as-seen-on-yahoo-finance.png?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/lZJbxi1cPcoXS7U1/as-seen-on-yahoo-finance.png?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/lZJbxi1cPcoXS7U1/as-seen-on-yahoo-finance.png?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/lZJbxi1cPcoXS7U1/as-seen-on-yahoo-finance.png?width=3840 3840w'
                        src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/lZJbxi1cPcoXS7U1/as-seen-on-yahoo-finance.png'
                        width={500}
                        height={184}
                        data-instant-type='image'
                        decoding='async'
                        loading='lazy'
                        className='i0Tckn1FDdlDw7RWZ'
                      />
                      <img
                        alt=''
                        srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/iC1XZHseiEE62PHd/as-seen-on-usa-today.png?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/iC1XZHseiEE62PHd/as-seen-on-usa-today.png?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/iC1XZHseiEE62PHd/as-seen-on-usa-today.png?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/iC1XZHseiEE62PHd/as-seen-on-usa-today.png?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/iC1XZHseiEE62PHd/as-seen-on-usa-today.png?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/iC1XZHseiEE62PHd/as-seen-on-usa-today.png?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/iC1XZHseiEE62PHd/as-seen-on-usa-today.png?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/iC1XZHseiEE62PHd/as-seen-on-usa-today.png?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/iC1XZHseiEE62PHd/as-seen-on-usa-today.png?width=3840 3840w'
                        src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/iC1XZHseiEE62PHd/as-seen-on-usa-today.png'
                        width={500}
                        height={91}
                        data-instant-type='image'
                        decoding='async'
                        loading='lazy'
                        className='ikQ4SHGKTz44OVXsX'
                      />
                      <img
                        alt=''
                        srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/IDh9VHS3lBflk6gQ/as-seen-on-real-homes.png?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/IDh9VHS3lBflk6gQ/as-seen-on-real-homes.png?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/IDh9VHS3lBflk6gQ/as-seen-on-real-homes.png?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/IDh9VHS3lBflk6gQ/as-seen-on-real-homes.png?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/IDh9VHS3lBflk6gQ/as-seen-on-real-homes.png?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/IDh9VHS3lBflk6gQ/as-seen-on-real-homes.png?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/IDh9VHS3lBflk6gQ/as-seen-on-real-homes.png?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/IDh9VHS3lBflk6gQ/as-seen-on-real-homes.png?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/IDh9VHS3lBflk6gQ/as-seen-on-real-homes.png?width=3840 3840w'
                        src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/IDh9VHS3lBflk6gQ/as-seen-on-real-homes.png'
                        width={500}
                        height={221}
                        data-instant-type='image'
                        decoding='async'
                        loading='lazy'
                        className='imPbOrS1GRJUaXXmC'
                      />
                      <img
                        alt=''
                        srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/nveEBABf3sklXcgF/seen-on-pr-newswire.png?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/nveEBABf3sklXcgF/seen-on-pr-newswire.png?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/nveEBABf3sklXcgF/seen-on-pr-newswire.png?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/nveEBABf3sklXcgF/seen-on-pr-newswire.png?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/nveEBABf3sklXcgF/seen-on-pr-newswire.png?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/nveEBABf3sklXcgF/seen-on-pr-newswire.png?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/nveEBABf3sklXcgF/seen-on-pr-newswire.png?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/nveEBABf3sklXcgF/seen-on-pr-newswire.png?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/nveEBABf3sklXcgF/seen-on-pr-newswire.png?width=3840 3840w'
                        src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/nveEBABf3sklXcgF/seen-on-pr-newswire.png'
                        width={500}
                        height={278}
                        data-instant-type='image'
                        decoding='async'
                        loading='lazy'
                        className='i6x0BfJEcHN95IeRe'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='iIRAz7PlTKBoJ3jQf'
                data-instant-type='container'
                id='iIRAz7PlTKBoJ3jQf'>
                <div
                  className='iODGJ18nMlaew6gN4'
                  data-instant-type='container'
                  data-instant-form-product-url='/products/turboblaze-air-fryer'
                  data-instant-form-variant-id='gid://shopify/ProductVariant/47075741335861'>
                  <img
                    alt=''
                    srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/Ll484qOniIPs16W0/40c737c7-4f28-4442-a59d-60a4ef5350cd.jpg?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/Ll484qOniIPs16W0/40c737c7-4f28-4442-a59d-60a4ef5350cd.jpg?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/Ll484qOniIPs16W0/40c737c7-4f28-4442-a59d-60a4ef5350cd.jpg?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/Ll484qOniIPs16W0/40c737c7-4f28-4442-a59d-60a4ef5350cd.jpg?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/Ll484qOniIPs16W0/40c737c7-4f28-4442-a59d-60a4ef5350cd.jpg?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/Ll484qOniIPs16W0/40c737c7-4f28-4442-a59d-60a4ef5350cd.jpg?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/Ll484qOniIPs16W0/40c737c7-4f28-4442-a59d-60a4ef5350cd.jpg?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/Ll484qOniIPs16W0/40c737c7-4f28-4442-a59d-60a4ef5350cd.jpg?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/Ll484qOniIPs16W0/40c737c7-4f28-4442-a59d-60a4ef5350cd.jpg?width=3840 3840w'
                    src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/Ll484qOniIPs16W0/40c737c7-4f28-4442-a59d-60a4ef5350cd.jpg'
                    width={800}
                    height={734}
                    data-instant-type='image'
                    decoding='async'
                    loading='lazy'
                    className='is5EWlh3PQpBIjiPL'
                    data-uw-rm-alt-original=''
                  />
                  <div
                    className='idz4VbxgIRxwShGvh'
                    data-instant-type='container'
                    href=''
                    rel='noopener noreferrer'>
                    <div
                      className='izbyndKhYhzIe8AOd'
                      data-instant-type='container'>
                      <div
                        className='ixc4hQqvrmvQN6mxJ'
                        data-instant-type='container'>
                        <div
                          data-instant-type='icon'
                          className='iOsDbCh3Nyf8FSNQj'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>star</title>
                            <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                          </svg>
                        </div>
                        <div
                          data-instant-type='icon'
                          className='iQIe0ZEe3qcmJSBDu'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>star</title>
                            <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                          </svg>
                        </div>
                        <div
                          data-instant-type='icon'
                          className='iDXggMaVw3OVwvSTn'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>star</title>
                            <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                          </svg>
                        </div>
                        <div
                          data-instant-type='icon'
                          className='izLXBCpIVBnlSRcHn'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>star</title>
                            <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                          </svg>
                        </div>
                        <div
                          data-instant-type='icon'
                          className='ic3eqWhkE5khK7eDn'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>star</title>
                            <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                          </svg>
                        </div>
                      </div>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text i1C4P1wGE4UYYK1Pm'>
                        <span>4.8/5.0 — 660+ reviews</span>
                      </span>
                    </div>
                    <h3
                      data-instant-type='text'
                      className='instant-rich-text iMipHKTGZtJ8rwNvW'>
                      <span>A World of Delicious Possibilities Awaits</span>
                    </h3>
                    <p
                      data-instant-type='text'
                      className='instant-rich-text iNiLJS4l88UE8NVOk'>
                      <span>
                        TurboBlaze™ transforms your kitchen by adding fun,
                        creativity, and convenience to every lifestyle.
                      </span>
                    </p>
                    <div
                      className='ifL2VA2thBIGeS6v8'
                      data-instant-type='container'>
                      <div
                        className='ikcYOijRrqzp4cNUH'
                        data-instant-type='container'>
                        <div
                          data-instant-type='icon'
                          className='iZpz20zq4SdyCQ1u9'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>check-circle</title>
                            <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                          </svg>
                        </div>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text i490oqRBzZ0cfq0fl'>
                          <span>9-in-1 Cooking Versatility</span>
                        </span>
                      </div>
                      <div
                        className='iMe5ly4fKADgTq5mt'
                        data-instant-type='container'>
                        <div
                          data-instant-type='icon'
                          className='inc8nLGoUKRXEEWKv'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>check-circle</title>
                            <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                          </svg>
                        </div>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text iyQiS5fcDZGLrVhKh'>
                          <span>Family Friendly 6.0-Quart Capacity</span>
                        </span>
                      </div>
                      <div
                        className='iISsMK3X8kkrBw3Lj'
                        data-instant-type='container'>
                        <div
                          data-instant-type='icon'
                          className='imARba5sdIPLWfMT5'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>check-circle</title>
                            <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                          </svg>
                        </div>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text iXFneanIycDFkyu9X'>
                          <span>Whisper-Quiet Operation</span>
                        </span>
                      </div>
                      <div
                        className='ifYNqr3mm4MqbHyXb'
                        data-instant-type='container'>
                        <div
                          data-instant-type='icon'
                          className='iMR4QEblwkIyehQFS'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>check-circle</title>
                            <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                          </svg>
                        </div>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text iTfcYdGXNyLPDYNh2'>
                          <span>Sleek Space-Saving Design</span>
                        </span>
                      </div>
                    </div>
                    <div
                      className='ibgkM54k9Aa4y22eh'
                      data-instant-type='container'>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text iPzZj997fD3lfOjBE'>
                        <span>$96</span>
                      </span>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text iLDYkIqEzA02mKJXj'>
                        <span>$119.99</span>
                      </span>
                    </div>
                    <a
                      className='iSMcyZETdT5ktWlDe'
                      data-instant-type='container'
                      href={stripeLink}
                      rel='noopener noreferrer'>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text iCLAba7gtXIoqUSIB'>
                        <span>Shop TurboBlaze • 20% Off</span>
                      </span>
                    </a>
                    <span
                      data-instant-type='text'
                      className='instant-rich-text iGnVDw0pgmbrOxieN'>
                      <span>30-Day Money-Back Guarantee</span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className='instant-slider-container iBVjGOIP0VDUobgGO'
                data-instant-type='slider-container'
                id='iBVjGOIP0VDUobgGO'>
                <div
                  className='i807ThoJsrrzH2wkd'
                  data-instant-type='container'>
                  <h3
                    data-instant-type='text'
                    className='instant-rich-text iOrlNli2LFcMxB06t'>
                    <span>What Our Customers Have to Say</span>
                  </h3>
                  <span
                    data-instant-type='text'
                    className='instant-rich-text ix2GuztGodFvHElwY'>
                    <span>
                      Hear from some of our amazing customers who are using
                      TurboBlaze.
                    </span>
                  </span>
                  <div
                    className='instant-slider iKc1La7yepRCEZiim instant-slider-initialized instant-slider-horizontal instant-slider-backface-hidden'
                    data-instant-type='slider'
                    id='Kc1La7yepRCEZiim'>
                    <div
                      className='instant-slider-wrapper'
                      id='swiper-wrapper-e1010c1c443d5f77a6'
                      aria-live='polite'>
                      <Swiper
                        modules={[Autoplay]}
                        className='mySwiper'
                        spaceBetween={12}
                        slidesPerView={3}
                        onSwiper={(sw) => setSwiper(sw)}
                        autoplay={{
                          delay: 7000,
                        }}
                        breakpoints={{
                          346: {
                            // width: 576,
                            slidesPerView: 1,
                          },
                          500: {
                            // width: 576,
                            slidesPerView: 1,
                          },
                          768: {
                            // width: 768,
                            slidesPerView: 3,
                          },
                        }}>
                        <SwiperSlide>
                          <div
                            className='instant-slider-slide ijSoHHfvSDFCgEpY3 instant-slider-slide-active'
                            data-instant-type='slider-slide'
                            role='group'
                            aria-label='1 / 4'>
                            <div
                              className='iM0qEf4qM9xZuQQcw'
                              data-instant-type='container'>
                              <div
                                className='iXcFf9KOBqwzJbRko'
                                data-instant-type='container'>
                                <div
                                  data-instant-type='icon'
                                  className='izO4pHb3oOc2mIiNY'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='currentColor'
                                    viewBox='0 0 256 256'
                                    className='instant-icon'>
                                    <title>star</title>
                                    <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                                  </svg>
                                </div>
                                <div
                                  data-instant-type='icon'
                                  className='ispZ6vC69GWmWvXZ5'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='currentColor'
                                    viewBox='0 0 256 256'
                                    className='instant-icon'>
                                    <title>star</title>
                                    <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                                  </svg>
                                </div>
                                <div
                                  data-instant-type='icon'
                                  className='i1PNT4TiEN6p3ckjH'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='currentColor'
                                    viewBox='0 0 256 256'
                                    className='instant-icon'>
                                    <title>star</title>
                                    <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                                  </svg>
                                </div>
                                <div
                                  data-instant-type='icon'
                                  className='iqkZsdSII1x6jfSdg'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='currentColor'
                                    viewBox='0 0 256 256'
                                    className='instant-icon'>
                                    <title>star</title>
                                    <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                                  </svg>
                                </div>
                                <div
                                  data-instant-type='icon'
                                  className='iBpNPSa5089W5IbpR'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='currentColor'
                                    viewBox='0 0 256 256'
                                    className='instant-icon'>
                                    <title>star</title>
                                    <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                                  </svg>
                                </div>
                              </div>
                              <p
                                data-instant-type='text'
                                className='instant-rich-text iYYnPQyP3dJ6A2nHy'>
                                <span>
                                  “I’ve used a few different air fryers and this
                                  one is by far my favorite. Large cooking
                                  basket. Easy to see and use controls. Easy
                                  clean up. Highly recommend this fryer.”
                                </span>
                              </p>
                            </div>
                            <div
                              className='i7Hhy8Z5k5T00c0mt'
                              data-instant-type='container'>
                              <img
                                alt=''
                                srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/zBTpwn6GKKT0yRXO/07a7881e-961a-4d72-9158-76e7bf87ea13cr570386386sx48.jpg?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/zBTpwn6GKKT0yRXO/07a7881e-961a-4d72-9158-76e7bf87ea13cr570386386sx48.jpg?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/zBTpwn6GKKT0yRXO/07a7881e-961a-4d72-9158-76e7bf87ea13cr570386386sx48.jpg?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/zBTpwn6GKKT0yRXO/07a7881e-961a-4d72-9158-76e7bf87ea13cr570386386sx48.jpg?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/zBTpwn6GKKT0yRXO/07a7881e-961a-4d72-9158-76e7bf87ea13cr570386386sx48.jpg?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/zBTpwn6GKKT0yRXO/07a7881e-961a-4d72-9158-76e7bf87ea13cr570386386sx48.jpg?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/zBTpwn6GKKT0yRXO/07a7881e-961a-4d72-9158-76e7bf87ea13cr570386386sx48.jpg?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/zBTpwn6GKKT0yRXO/07a7881e-961a-4d72-9158-76e7bf87ea13cr570386386sx48.jpg?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/zBTpwn6GKKT0yRXO/07a7881e-961a-4d72-9158-76e7bf87ea13cr570386386sx48.jpg?width=3840 3840w'
                                src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/zBTpwn6GKKT0yRXO/07a7881e-961a-4d72-9158-76e7bf87ea13cr570386386sx48.jpg'
                                width={48}
                                height={48}
                                data-instant-type='image'
                                decoding='async'
                                loading='lazy'
                                className='iVFgtrbZLnHYTWSsZ'
                                data-uw-rm-alt-original=''
                              />
                              <span
                                data-instant-type='text'
                                className='instant-rich-text iCKoIGQyCxC5USa2u'>
                                <span>Margie</span>
                              </span>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div
                            className='instant-slider-slide iNyRCBeShUV8cQjtl instant-slider-slide-next'
                            data-instant-type='slider-slide'
                            role='group'
                            aria-label='2 / 4'>
                            <div
                              className='ichSoyGQxKvzqlGZM'
                              data-instant-type='container'>
                              <div
                                className='i6O5wNokcm5wVDkis'
                                data-instant-type='container'>
                                <div
                                  data-instant-type='icon'
                                  className='iY7pY9BRRXEXFgv4x'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='currentColor'
                                    viewBox='0 0 256 256'
                                    className='instant-icon'>
                                    <title>star</title>
                                    <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                                  </svg>
                                </div>
                                <div
                                  data-instant-type='icon'
                                  className='iOzBma89EIbuImdaZ'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='currentColor'
                                    viewBox='0 0 256 256'
                                    className='instant-icon'>
                                    <title>star</title>
                                    <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                                  </svg>
                                </div>
                                <div
                                  data-instant-type='icon'
                                  className='iyxvTB2yILiaQ5r8H'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='currentColor'
                                    viewBox='0 0 256 256'
                                    className='instant-icon'>
                                    <title>star</title>
                                    <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                                  </svg>
                                </div>
                                <div
                                  data-instant-type='icon'
                                  className='iEc7Dwv2BbA9tRx0c'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='currentColor'
                                    viewBox='0 0 256 256'
                                    className='instant-icon'>
                                    <title>star</title>
                                    <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                                  </svg>
                                </div>
                                <div
                                  data-instant-type='icon'
                                  className='i2AmvSklechy4159g'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='currentColor'
                                    viewBox='0 0 256 256'
                                    className='instant-icon'>
                                    <title>star</title>
                                    <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                                  </svg>
                                </div>
                              </div>
                              <p
                                data-instant-type='text'
                                className='instant-rich-text ihCVjP1l39vB92Iga'>
                                <span>
                                  “Switched from a Nuwave to Cosori air fryer.
                                  The Cosori model is quieter, has a larger yet
                                  equally space-efficient basket, &amp; is easy
                                  to clean. It excels in cooking, crisping
                                  frozen fries unlike the old unit.”
                                </span>
                              </p>
                            </div>
                            <div
                              className='ixKhxYft2sMqWOkDv'
                              data-instant-type='container'>
                              <img
                                alt=''
                                srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/XvkMExZX0lFiBc1l/aef719eb-b721-43da-bbe2-fcd9be2ca21ecr70471471sx48.jpg?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/XvkMExZX0lFiBc1l/aef719eb-b721-43da-bbe2-fcd9be2ca21ecr70471471sx48.jpg?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/XvkMExZX0lFiBc1l/aef719eb-b721-43da-bbe2-fcd9be2ca21ecr70471471sx48.jpg?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/XvkMExZX0lFiBc1l/aef719eb-b721-43da-bbe2-fcd9be2ca21ecr70471471sx48.jpg?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/XvkMExZX0lFiBc1l/aef719eb-b721-43da-bbe2-fcd9be2ca21ecr70471471sx48.jpg?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/XvkMExZX0lFiBc1l/aef719eb-b721-43da-bbe2-fcd9be2ca21ecr70471471sx48.jpg?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/XvkMExZX0lFiBc1l/aef719eb-b721-43da-bbe2-fcd9be2ca21ecr70471471sx48.jpg?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/XvkMExZX0lFiBc1l/aef719eb-b721-43da-bbe2-fcd9be2ca21ecr70471471sx48.jpg?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/XvkMExZX0lFiBc1l/aef719eb-b721-43da-bbe2-fcd9be2ca21ecr70471471sx48.jpg?width=3840 3840w'
                                src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/XvkMExZX0lFiBc1l/aef719eb-b721-43da-bbe2-fcd9be2ca21ecr70471471sx48.jpg'
                                width={48}
                                height={48}
                                data-instant-type='image'
                                decoding='async'
                                loading='lazy'
                                className='iCch8XORdKMEPVerR'
                                data-uw-rm-alt-original=''
                              />
                              <span
                                data-instant-type='text'
                                className='instant-rich-text iPmbMnvBpdAcV7VS0'>
                                <span>
                                  <a
                                    target='_blank'
                                    rel='noopener noreferrer nofollow'
                                    className='a-profile'
                                    href='https://www.amazon.com/gp/profile/amzn1.account.AHTVGSUOMERKF2B3K3D6CPC3XLKA/ref=cm_cr_getr_d_gw_btm?ie=UTF8'>
                                    Nicole K.
                                  </a>
                                </span>
                              </span>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div
                            className='instant-slider-slide i1HjYT8NoeaOaMYPz'
                            data-instant-type='slider-slide'
                            role='group'
                            aria-label='3 / 4'>
                            <div
                              className='iYt90OuBVjcUFHB6L'
                              data-instant-type='container'>
                              <div
                                className='iQA9tA4KYy9ie50Hq'
                                data-instant-type='container'>
                                <div
                                  data-instant-type='icon'
                                  className='i4zNEEmOuAmdNZmY5'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='currentColor'
                                    viewBox='0 0 256 256'
                                    className='instant-icon'>
                                    <title>star</title>
                                    <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                                  </svg>
                                </div>
                                <div
                                  data-instant-type='icon'
                                  className='ivEkcFXfXxYOq8wNp'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='currentColor'
                                    viewBox='0 0 256 256'
                                    className='instant-icon'>
                                    <title>star</title>
                                    <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                                  </svg>
                                </div>
                                <div
                                  data-instant-type='icon'
                                  className='iXgCzoUQLZdQWjWRE'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='currentColor'
                                    viewBox='0 0 256 256'
                                    className='instant-icon'>
                                    <title>star</title>
                                    <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                                  </svg>
                                </div>
                                <div
                                  data-instant-type='icon'
                                  className='iV2dd5FZKfodcecLn'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='currentColor'
                                    viewBox='0 0 256 256'
                                    className='instant-icon'>
                                    <title>star</title>
                                    <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                                  </svg>
                                </div>
                                <div
                                  data-instant-type='icon'
                                  className='iqObyEKe8DdyhRZfI'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='currentColor'
                                    viewBox='0 0 256 256'
                                    className='instant-icon'>
                                    <title>star</title>
                                    <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                                  </svg>
                                </div>
                              </div>
                              <p
                                data-instant-type='text'
                                className='instant-rich-text iOhxjwynODnnEKYqe'>
                                <span>
                                  “This is my 6th Cosori air fryer. And this new
                                  model, like the rest, does not disappoint.
                                  Unlike the Dual Blaze by Cosori, this only
                                  cooks from the top, BUT it cooks at higher
                                  temps than the Dual Blaze that is limited to
                                  400 degrees.”
                                </span>
                              </p>
                            </div>
                            <div
                              className='idGr3Cp0F1lRmVlaq'
                              data-instant-type='container'>
                              <img
                                alt=''
                                srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/3Gbr0mTX4RZv8ZsM/060b1b4e-01c9-44ad-a1d4-aedaff8661d3cr420416416sx48.jpg?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/3Gbr0mTX4RZv8ZsM/060b1b4e-01c9-44ad-a1d4-aedaff8661d3cr420416416sx48.jpg?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/3Gbr0mTX4RZv8ZsM/060b1b4e-01c9-44ad-a1d4-aedaff8661d3cr420416416sx48.jpg?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/3Gbr0mTX4RZv8ZsM/060b1b4e-01c9-44ad-a1d4-aedaff8661d3cr420416416sx48.jpg?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/3Gbr0mTX4RZv8ZsM/060b1b4e-01c9-44ad-a1d4-aedaff8661d3cr420416416sx48.jpg?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/3Gbr0mTX4RZv8ZsM/060b1b4e-01c9-44ad-a1d4-aedaff8661d3cr420416416sx48.jpg?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/3Gbr0mTX4RZv8ZsM/060b1b4e-01c9-44ad-a1d4-aedaff8661d3cr420416416sx48.jpg?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/3Gbr0mTX4RZv8ZsM/060b1b4e-01c9-44ad-a1d4-aedaff8661d3cr420416416sx48.jpg?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/3Gbr0mTX4RZv8ZsM/060b1b4e-01c9-44ad-a1d4-aedaff8661d3cr420416416sx48.jpg?width=3840 3840w'
                                src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/3Gbr0mTX4RZv8ZsM/060b1b4e-01c9-44ad-a1d4-aedaff8661d3cr420416416sx48.jpg'
                                width={48}
                                height={48}
                                data-instant-type='image'
                                decoding='async'
                                loading='lazy'
                                className='i3pjzic0iC73ZhDxR'
                                data-uw-rm-alt-original=''
                              />
                              <span
                                data-instant-type='text'
                                className='instant-rich-text iq1fac6PMN78uMLLG'>
                                <span>Mike L.</span>
                              </span>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div
                            className='instant-slider-slide iM70FrkSvzBrayl6k'
                            data-instant-type='slider-slide'
                            role='group'
                            aria-label='4 / 4'>
                            <div
                              className='inDCBukZRh4AyBbrB'
                              data-instant-type='container'>
                              <div
                                className='iISibsUnI1sZBc6rz'
                                data-instant-type='container'>
                                <div
                                  data-instant-type='icon'
                                  className='iAx9uUwnUtOCfa600'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='currentColor'
                                    viewBox='0 0 256 256'
                                    className='instant-icon'>
                                    <title>star</title>
                                    <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                                  </svg>
                                </div>
                                <div
                                  data-instant-type='icon'
                                  className='iCIrchR0SfRDodPdw'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='currentColor'
                                    viewBox='0 0 256 256'
                                    className='instant-icon'>
                                    <title>star</title>
                                    <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                                  </svg>
                                </div>
                                <div
                                  data-instant-type='icon'
                                  className='idNNWAbu1M6dtOssF'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='currentColor'
                                    viewBox='0 0 256 256'
                                    className='instant-icon'>
                                    <title>star</title>
                                    <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                                  </svg>
                                </div>
                                <div
                                  data-instant-type='icon'
                                  className='iYR4e7pUJTdtMFMfZ'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='currentColor'
                                    viewBox='0 0 256 256'
                                    className='instant-icon'>
                                    <title>star</title>
                                    <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                                  </svg>
                                </div>
                                <div
                                  data-instant-type='icon'
                                  className='iYDjQAoOFW4ie4sLc'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='currentColor'
                                    viewBox='0 0 256 256'
                                    className='instant-icon'>
                                    <title>star</title>
                                    <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                                  </svg>
                                </div>
                              </div>
                              <p
                                data-instant-type='text'
                                className='instant-rich-text i5MdPlbInqDkx8CYS'>
                                <span>
                                  “My first air fryer. Day one washed basket,
                                  rack and wiped inside. Did test run, made
                                  salmon with a couple veggies, frozen broccoli
                                  &amp; carrots. All came out perfect.”
                                </span>
                              </p>
                            </div>
                            <div
                              className='iS00n8YSIzMqZzlJd'
                              data-instant-type='container'>
                              <img
                                alt=''
                                srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/2QS3DNuxJ7URwiWp/080aa35e-19ad-426a-80c4-f510716e8311cr00233233sx48.jpg?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/2QS3DNuxJ7URwiWp/080aa35e-19ad-426a-80c4-f510716e8311cr00233233sx48.jpg?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/2QS3DNuxJ7URwiWp/080aa35e-19ad-426a-80c4-f510716e8311cr00233233sx48.jpg?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/2QS3DNuxJ7URwiWp/080aa35e-19ad-426a-80c4-f510716e8311cr00233233sx48.jpg?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/2QS3DNuxJ7URwiWp/080aa35e-19ad-426a-80c4-f510716e8311cr00233233sx48.jpg?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/2QS3DNuxJ7URwiWp/080aa35e-19ad-426a-80c4-f510716e8311cr00233233sx48.jpg?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/2QS3DNuxJ7URwiWp/080aa35e-19ad-426a-80c4-f510716e8311cr00233233sx48.jpg?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/2QS3DNuxJ7URwiWp/080aa35e-19ad-426a-80c4-f510716e8311cr00233233sx48.jpg?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/2QS3DNuxJ7URwiWp/080aa35e-19ad-426a-80c4-f510716e8311cr00233233sx48.jpg?width=3840 3840w'
                                src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/2QS3DNuxJ7URwiWp/080aa35e-19ad-426a-80c4-f510716e8311cr00233233sx48.jpg'
                                width={48}
                                height={48}
                                data-instant-type='image'
                                decoding='async'
                                loading='lazy'
                                className='icRc4z1ASRNTDtNaz'
                                data-uw-rm-alt-original=''
                              />
                              <span
                                data-instant-type='text'
                                className='instant-rich-text igUL8zca0BegIr41U'>
                                <span>Mr. Michael</span>
                              </span>
                            </div>
                          </div>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                    <span
                      className='instant-slider-notification'
                      aria-live='assertive'
                      aria-atomic='true'
                    />
                  </div>
                  <div
                    className='iETsUDbcz8rztPYat'
                    data-instant-type='container'>
                    <button
                      className='instant-slider-button instant-slider-button-prev instant-slider-BVjGOIP0VDUobgGO-button-prev ighGjBdKGqeaVZv3y'
                      data-instant-type='slider-button-prev'
                      disabled=''
                      tabIndex={-1}
                      aria-label='Previous slide'
                      aria-controls='swiper-wrapper-e1010c1c443d5f77a6'
                      aria-disabled='true'
                      onClick={() => swiper.slidePrev()}>
                      <div
                        data-instant-type='icon'
                        className='iDO7qAx8YwXwJDnUy'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 256 256'
                          className='instant-icon'>
                          <title>arrow-left</title>
                          <path d='M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z' />
                        </svg>
                      </div>
                    </button>
                    <button
                      className='instant-slider-button instant-slider-button-next instant-slider-BVjGOIP0VDUobgGO-button-next iS8hw5jHzFT72455c'
                      data-instant-type='slider-button-next'
                      tabIndex={0}
                      aria-label='Next slide'
                      aria-controls='swiper-wrapper-e1010c1c443d5f77a6'
                      aria-disabled='false'
                      onClick={() => swiper.slideNext()}>
                      <div
                        data-instant-type='icon'
                        className='iwDV1vQYWbr6XU5cc'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 256 256'
                          className='instant-icon'>
                          <title>arrow-right</title>
                          <path d='M221.66,133.66l-72,72a8,8,0,0,1-11.32-11.32L196.69,136H40a8,8,0,0,1,0-16H196.69L138.34,61.66a8,8,0,0,1,11.32-11.32l72,72A8,8,0,0,1,221.66,133.66Z' />
                        </svg>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div
                className='i2Yay8OadNL2hpOLd'
                data-instant-type='container'
                id='i2Yay8OadNL2hpOLd'>
                <div
                  className='iWGdOCk1JPyTp4c53'
                  data-instant-type='container'>
                  <div
                    className='ib1Xbv8RkrFjN7wLF'
                    data-instant-type='container'>
                    <h3
                      data-instant-type='text'
                      className='instant-rich-text itrc68pTOb5LDeMvX'>
                      <span>
                        <strong>Mastering Mealtime For Every Lifestyle</strong>
                      </span>
                    </h3>
                    <span
                      data-instant-type='text'
                      className='instant-rich-text iEBw2fJ2OynLkCxqz'>
                      <span>
                        Whether you're hosting the game day party of the year,
                        juggling family meals in a busy household, or exploring
                        healthy eating without the hassle, the Cosori
                        TurboBlaze™ is your trusted partner in the kitchen.
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className='i9pj4L8LsShz6hEgQ'
                data-instant-type='container'
                id='i9pj4L8LsShz6hEgQ'>
                <div
                  className='igJotT1hLX7eRrIx8'
                  data-instant-type='container'>
                  <img
                    alt=''
                    srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/ag9u0eKjwXklmblU/1af52bff-9172-4d9a-a1ed-cd2c7ec4dee7.jpg?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/ag9u0eKjwXklmblU/1af52bff-9172-4d9a-a1ed-cd2c7ec4dee7.jpg?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/ag9u0eKjwXklmblU/1af52bff-9172-4d9a-a1ed-cd2c7ec4dee7.jpg?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/ag9u0eKjwXklmblU/1af52bff-9172-4d9a-a1ed-cd2c7ec4dee7.jpg?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/ag9u0eKjwXklmblU/1af52bff-9172-4d9a-a1ed-cd2c7ec4dee7.jpg?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/ag9u0eKjwXklmblU/1af52bff-9172-4d9a-a1ed-cd2c7ec4dee7.jpg?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/ag9u0eKjwXklmblU/1af52bff-9172-4d9a-a1ed-cd2c7ec4dee7.jpg?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/ag9u0eKjwXklmblU/1af52bff-9172-4d9a-a1ed-cd2c7ec4dee7.jpg?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/ag9u0eKjwXklmblU/1af52bff-9172-4d9a-a1ed-cd2c7ec4dee7.jpg?width=3840 3840w'
                    src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/ag9u0eKjwXklmblU/1af52bff-9172-4d9a-a1ed-cd2c7ec4dee7.jpg'
                    width={800}
                    height={448}
                    loading='lazy'
                    decoding='async'
                    className='instant-fill'
                    data-uw-rm-alt-original=''
                  />
                  <div
                    className='iUhRr2Npr1gVXDraT'
                    data-instant-type='container'>
                    <div
                      className='iChoowFSewQ8srfyA'
                      data-instant-type='container'>
                      <h3
                        data-instant-type='text'
                        className='instant-rich-text iy3uXB1UPka2HaEfS'>
                        <span>The Party Host's Dream Appliance</span>
                      </h3>
                      <div
                        className='iHPxZlBmSHqpkYaoL'
                        data-instant-type='container'>
                        <div
                          className='iBaZF7AKBvd97Cagl'
                          data-instant-type='container'>
                          <div
                            data-instant-type='icon'
                            className='iJdVVJhiKGlw2hL0H'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='currentColor'
                              viewBox='0 0 256 256'
                              className='instant-icon'>
                              <title>check-circle</title>
                              <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                            </svg>
                          </div>
                          <span
                            data-instant-type='text'
                            className='instant-rich-text i4QvLCnBF9wAEh5wo'>
                            <span>Quick Wings &amp; Appetizers</span>
                          </span>
                        </div>
                        <div
                          className='i8HUrmDhWCAv8qf17'
                          data-instant-type='container'>
                          <div
                            data-instant-type='icon'
                            className='i7Bpc4KQ4wuxZnWWz'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='currentColor'
                              viewBox='0 0 256 256'
                              className='instant-icon'>
                              <title>check-circle</title>
                              <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                            </svg>
                          </div>
                          <span
                            data-instant-type='text'
                            className='instant-rich-text iiCZNQatLfCw8PEOI'>
                            <span>Unforgettable Recipes</span>
                          </span>
                        </div>
                        <div
                          className='iWMsFhA4li1uetMqh'
                          data-instant-type='container'>
                          <div
                            data-instant-type='icon'
                            className='iC1IlhKN9FffPGeO9'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='currentColor'
                              viewBox='0 0 256 256'
                              className='instant-icon'>
                              <title>check-circle</title>
                              <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                            </svg>
                          </div>
                          <span
                            data-instant-type='text'
                            className='instant-rich-text iLL6aFXKQbt3neARw'>
                            <span>Keeps Snacks Hot</span>
                          </span>
                        </div>
                        <div
                          className='iUwX7Hn38SbqTbtGC'
                          data-instant-type='container'>
                          <div
                            data-instant-type='icon'
                            className='iB4gckvlgrlT77YUo'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='currentColor'
                              viewBox='0 0 256 256'
                              className='instant-icon'>
                              <title>check-circle</title>
                              <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                            </svg>
                          </div>
                          <span
                            data-instant-type='text'
                            className='instant-rich-text igoUSGU8zAXmxLV12'>
                            <span>Less Work, Entertain More</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='isgeVpT5cCcWzdj2W'
                data-instant-type='container'
                id='isgeVpT5cCcWzdj2W'>
                <div
                  className='iZaQS2RLsez20pqjR'
                  data-instant-type='container'>
                  <img
                    alt=''
                    srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/1G4aG0E7T2p1uhPU/586277375350574299.jpg?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/1G4aG0E7T2p1uhPU/586277375350574299.jpg?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/1G4aG0E7T2p1uhPU/586277375350574299.jpg?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/1G4aG0E7T2p1uhPU/586277375350574299.jpg?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/1G4aG0E7T2p1uhPU/586277375350574299.jpg?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/1G4aG0E7T2p1uhPU/586277375350574299.jpg?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/1G4aG0E7T2p1uhPU/586277375350574299.jpg?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/1G4aG0E7T2p1uhPU/586277375350574299.jpg?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/1G4aG0E7T2p1uhPU/586277375350574299.jpg?width=3840 3840w'
                    src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/1G4aG0E7T2p1uhPU/586277375350574299.jpg'
                    width={3000}
                    height={1570}
                    loading='lazy'
                    decoding='async'
                    className='instant-fill'
                    data-uw-rm-alt-original=''
                  />
                  <div
                    className='ii2IDuYwVpo0yboyF'
                    data-instant-type='container'>
                    <div
                      className='iYsZJHfrIKcPcC0EN'
                      data-instant-type='container'>
                      <h3
                        data-instant-type='text'
                        className='instant-rich-text idKDKCKlCWYvyqBF5'>
                        <span>The Busy Parent's Kitchen Hero</span>
                      </h3>
                      <div
                        className='iBmbEiNqETHndL6EV'
                        data-instant-type='container'>
                        <div
                          className='iIWodx7RUNK5XY6e4'
                          data-instant-type='container'>
                          <div
                            data-instant-type='icon'
                            className='ipT7P0nDVE68xAhe4'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='currentColor'
                              viewBox='0 0 256 256'
                              className='instant-icon'>
                              <title>check-circle</title>
                              <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                            </svg>
                          </div>
                          <span
                            data-instant-type='text'
                            className='instant-rich-text iOwBHGBS3suuMOMsg'>
                            <span>Speedy, Healthy Meals</span>
                          </span>
                        </div>
                        <div
                          className='iNj7w8TSvaQczjYBe'
                          data-instant-type='container'>
                          <div
                            data-instant-type='icon'
                            className='iRVHSQM9WybrzpK2V'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='currentColor'
                              viewBox='0 0 256 256'
                              className='instant-icon'>
                              <title>check-circle</title>
                              <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                            </svg>
                          </div>
                          <span
                            data-instant-type='text'
                            className='instant-rich-text iKlzgHvKFz3rjGM9a'>
                            <span>Kid Friendly Recipes</span>
                          </span>
                        </div>
                        <div
                          className='iGkf7Ci1nvY9XToSQ'
                          data-instant-type='container'>
                          <div
                            data-instant-type='icon'
                            className='iwOc6zBzqA12ZRq9C'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='currentColor'
                              viewBox='0 0 256 256'
                              className='instant-icon'>
                              <title>check-circle</title>
                              <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                            </svg>
                          </div>
                          <span
                            data-instant-type='text'
                            className='instant-rich-text iH1Fsbt94WhiM2X9o'>
                            <span>Easy Meal Prep</span>
                          </span>
                        </div>
                        <div
                          className='iEhbPgQTYRbRf7EsK'
                          data-instant-type='container'>
                          <div
                            data-instant-type='icon'
                            className='iCSjDpCrQEBuSlkGr'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='currentColor'
                              viewBox='0 0 256 256'
                              className='instant-icon'>
                              <title>check-circle</title>
                              <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                            </svg>
                          </div>
                          <span
                            data-instant-type='text'
                            className='instant-rich-text iMpwcly3VlYDQHXod'>
                            <span>Quick to Clean</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='i8ciFCWmAqjbDL8Oi'
                data-instant-type='container'
                id='i8ciFCWmAqjbDL8Oi'>
                <div
                  className='iRwbKwr3zn7OEFrhL'
                  data-instant-type='container'>
                  <img
                    alt=''
                    srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/Dm877oycMOBqjelh/00569caf-8c3a-4d8d-a27d-a349dda08106.jpg?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/Dm877oycMOBqjelh/00569caf-8c3a-4d8d-a27d-a349dda08106.jpg?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/Dm877oycMOBqjelh/00569caf-8c3a-4d8d-a27d-a349dda08106.jpg?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/Dm877oycMOBqjelh/00569caf-8c3a-4d8d-a27d-a349dda08106.jpg?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/Dm877oycMOBqjelh/00569caf-8c3a-4d8d-a27d-a349dda08106.jpg?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/Dm877oycMOBqjelh/00569caf-8c3a-4d8d-a27d-a349dda08106.jpg?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/Dm877oycMOBqjelh/00569caf-8c3a-4d8d-a27d-a349dda08106.jpg?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/Dm877oycMOBqjelh/00569caf-8c3a-4d8d-a27d-a349dda08106.jpg?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/Dm877oycMOBqjelh/00569caf-8c3a-4d8d-a27d-a349dda08106.jpg?width=3840 3840w'
                    src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/Dm877oycMOBqjelh/00569caf-8c3a-4d8d-a27d-a349dda08106.jpg'
                    width={800}
                    height={450}
                    loading='lazy'
                    decoding='async'
                    className='instant-fill'
                    data-uw-rm-alt-original=''
                  />
                  <div
                    className='iP2xhdHd1yQBmOHqR'
                    data-instant-type='container'>
                    <div
                      className='iqw5bxDcND9xp85i0'
                      data-instant-type='container'>
                      <h3
                        data-instant-type='text'
                        className='instant-rich-text iI1HHc3MVLaLK9oJJ'>
                        <span>The Perfect Partner In Health</span>
                      </h3>
                      <div
                        className='iYBuAkMy9aihrjVXn'
                        data-instant-type='container'>
                        <div
                          className='ivJjG7pDgoPA8QYow'
                          data-instant-type='container'>
                          <div
                            data-instant-type='icon'
                            className='iFHE4hMIuQAe6L12C'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='currentColor'
                              viewBox='0 0 256 256'
                              className='instant-icon'>
                              <title>check-circle</title>
                              <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                            </svg>
                          </div>
                          <span
                            data-instant-type='text'
                            className='instant-rich-text i1MqnUzPHId2yKkxQ'>
                            <span>Uses Up To 95% Less Oil</span>
                          </span>
                        </div>
                        <div
                          className='iOhQLM9f3u7s6umgF'
                          data-instant-type='container'>
                          <div
                            data-instant-type='icon'
                            className='ilTFNFqDqq6w7OkqF'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='currentColor'
                              viewBox='0 0 256 256'
                              className='instant-icon'>
                              <title>check-circle</title>
                              <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                            </svg>
                          </div>
                          <span
                            data-instant-type='text'
                            className='instant-rich-text ii1ZyuMa95Dpz0XW5'>
                            <span>Fast, Yet Bursting with Flavor</span>
                          </span>
                        </div>
                        <div
                          className='iiJSunwCegAegPQUL'
                          data-instant-type='container'>
                          <div
                            data-instant-type='icon'
                            className='ixVpc6TwTLQw3Sdvh'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='currentColor'
                              viewBox='0 0 256 256'
                              className='instant-icon'>
                              <title>check-circle</title>
                              <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                            </svg>
                          </div>
                          <span
                            data-instant-type='text'
                            className='instant-rich-text ibnpU7CepVGGDG7BB'>
                            <span>From Freezer to Feast in Minutes</span>
                          </span>
                        </div>
                        <div
                          className='imv8B7QeUy6NSCafx'
                          data-instant-type='container'>
                          <div
                            data-instant-type='icon'
                            className='i9T1H1l3tlzLEGB0Y'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='currentColor'
                              viewBox='0 0 256 256'
                              className='instant-icon'>
                              <title>check-circle</title>
                              <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                            </svg>
                          </div>
                          <span
                            data-instant-type='text'
                            className='instant-rich-text iPvxMjcZxofUSWci7'>
                            <span>Health Recipes That Don't Disappoint</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='iPYWJpIKEWz9uudv4'
                data-instant-type='container'
                id='iPYWJpIKEWz9uudv4'>
                <div
                  className='ikAHeKeJAyPQrYh93'
                  data-instant-type='container'>
                  <img
                    alt=''
                    srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/ADEcV2nSbzuo4jjw/turboblazeproduct043-large.jpg?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/ADEcV2nSbzuo4jjw/turboblazeproduct043-large.jpg?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/ADEcV2nSbzuo4jjw/turboblazeproduct043-large.jpg?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/ADEcV2nSbzuo4jjw/turboblazeproduct043-large.jpg?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/ADEcV2nSbzuo4jjw/turboblazeproduct043-large.jpg?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/ADEcV2nSbzuo4jjw/turboblazeproduct043-large.jpg?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/ADEcV2nSbzuo4jjw/turboblazeproduct043-large.jpg?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/ADEcV2nSbzuo4jjw/turboblazeproduct043-large.jpg?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/ADEcV2nSbzuo4jjw/turboblazeproduct043-large.jpg?width=3840 3840w'
                    src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/ADEcV2nSbzuo4jjw/turboblazeproduct043-large.jpg'
                    width={1280}
                    height={853}
                    loading='lazy'
                    decoding='async'
                    className='instant-fill'
                    data-uw-rm-alt-original=''
                  />
                  <div
                    className='i2YzVW3NZKtKq1xYM'
                    data-instant-type='container'>
                    <div
                      className='iqh2UkkN2BmLQ1Nmn'
                      data-instant-type='container'>
                      <h3
                        data-instant-type='text'
                        className='instant-rich-text iFLsllIlqWKuoI22b'>
                        <span>The Fashionista's Sleek Selection</span>
                      </h3>
                      <div
                        className='i47QRdGIFMJJJ97ao'
                        data-instant-type='container'>
                        <div
                          className='iEYGKuuQZ7X6PdMFe'
                          data-instant-type='container'>
                          <div
                            data-instant-type='icon'
                            className='ibnn8jPrMpVhIIlRB'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='currentColor'
                              viewBox='0 0 256 256'
                              className='instant-icon'>
                              <title>check-circle</title>
                              <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                            </svg>
                          </div>
                          <span
                            data-instant-type='text'
                            className='instant-rich-text isAC4hPpcVo9S0AYc'>
                            <span>Stylish Addition</span>
                          </span>
                        </div>
                        <div
                          className='is16VjfesxlgxCwka'
                          data-instant-type='container'>
                          <div
                            data-instant-type='icon'
                            className='idrOOS3ygydrMtpdv'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='currentColor'
                              viewBox='0 0 256 256'
                              className='instant-icon'>
                              <title>check-circle</title>
                              <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                            </svg>
                          </div>
                          <span
                            data-instant-type='text'
                            className='instant-rich-text iy6nyLIKKQo7DA5vp'>
                            <span>Compact Elegance</span>
                          </span>
                        </div>
                        <div
                          className='iRStsZBPbfjZ3zyON'
                          data-instant-type='container'>
                          <div
                            data-instant-type='icon'
                            className='iSLvKCq2pvsscnK90'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='currentColor'
                              viewBox='0 0 256 256'
                              className='instant-icon'>
                              <title>check-circle</title>
                              <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                            </svg>
                          </div>
                          <span
                            data-instant-type='text'
                            className='instant-rich-text i2izdPCnZhT4nTIMq'>
                            <span>Beautiful Easy-To-Use Display</span>
                          </span>
                        </div>
                        <div
                          className='iUkPbFWOjPm6lVXLE'
                          data-instant-type='container'>
                          <div
                            data-instant-type='icon'
                            className='i3KyKw9Y2wDe94Kxh'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='currentColor'
                              viewBox='0 0 256 256'
                              className='instant-icon'>
                              <title>check-circle</title>
                              <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                            </svg>
                          </div>
                          <span
                            data-instant-type='text'
                            className='instant-rich-text ieOODqXoOZQGQ40Me'>
                            <span>Gourmet With Ease</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='i3DExg9YXOfvUopkX'
                data-instant-type='container'
                id='i3DExg9YXOfvUopkX'>
                <div
                  className='ipcsbZdieEHb5Y8Eu'
                  data-instant-type='container'>
                  <a
                    className='iJYTFHISwrSdZrzkd'
                    data-instant-type='container'
                    href={stripeLink}
                    rel='noopener noreferrer'>
                    <span
                      data-instant-type='text'
                      className='instant-rich-text imvXdljFd2e6tCHdO'>
                      <span>Shop TurboBlaze • 20% Off</span>
                    </span>
                  </a>
                </div>
                <span
                  style={{
                    marginTop: '-14px',
                    textAlign: 'center',
                    color: '#212121',
                  }}>
                  Get the TurboBlaze™ with <strong>FREE SHIPPING</strong> —
                  within 7 Business Days.
                </span>
              </div>
              <div
                className='inEIpchDuFzxYCZkz'
                data-instant-type='container'
                id='inEIpchDuFzxYCZkz'>
                <div
                  className='i1pGWIFTHbvLu4R5d'
                  data-instant-type='container'>
                  <h4
                    data-instant-type='text'
                    className='instant-rich-text iN7LBmfCz21xeQ2iE'>
                    <span>Experience the TurboBlaze™ Difference</span>
                  </h4>
                  <h2
                    data-instant-type='text'
                    className='instant-rich-text iDE3fpog3XOpqzA2l'>
                    <span>
                      <strong>Get Crispy Perfection Every Time</strong>
                    </span>
                  </h2>
                  <div
                    className='i7FntlRsCKlJGtRMD'
                    data-instant-type='container'>
                    <div
                      className='itXKKo3vx0rCSBk57'
                      data-instant-type='container'>
                      <img
                        alt=''
                        srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/j2Fxg1CXOQ5ouGbQ/b574f242-fba4-44da-b3c2-62a814c0de7a.jpg?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/j2Fxg1CXOQ5ouGbQ/b574f242-fba4-44da-b3c2-62a814c0de7a.jpg?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/j2Fxg1CXOQ5ouGbQ/b574f242-fba4-44da-b3c2-62a814c0de7a.jpg?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/j2Fxg1CXOQ5ouGbQ/b574f242-fba4-44da-b3c2-62a814c0de7a.jpg?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/j2Fxg1CXOQ5ouGbQ/b574f242-fba4-44da-b3c2-62a814c0de7a.jpg?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/j2Fxg1CXOQ5ouGbQ/b574f242-fba4-44da-b3c2-62a814c0de7a.jpg?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/j2Fxg1CXOQ5ouGbQ/b574f242-fba4-44da-b3c2-62a814c0de7a.jpg?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/j2Fxg1CXOQ5ouGbQ/b574f242-fba4-44da-b3c2-62a814c0de7a.jpg?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/j2Fxg1CXOQ5ouGbQ/b574f242-fba4-44da-b3c2-62a814c0de7a.jpg?width=3840 3840w'
                        src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/j2Fxg1CXOQ5ouGbQ/b574f242-fba4-44da-b3c2-62a814c0de7a.jpg'
                        width={800}
                        height={448}
                        data-instant-type='image'
                        decoding='async'
                        loading='lazy'
                        className='iOZVcnTfheEGLfoNH'
                        data-uw-rm-alt-original=''
                      />
                      <span
                        data-instant-type='text'
                        className='instant-rich-text i5MfSyn4xheMN7nWJ'>
                        <span>Multi-Speed</span>
                        <span>Airflow System</span>
                      </span>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text i36EFnpr4zGyCigye'>
                        <span>
                          Variable speeds and even heat distribution for optimal
                          crisping.
                        </span>
                      </span>
                    </div>
                    <div
                      className='imTAlRjBvnhhgf9IU'
                      data-instant-type='container'>
                      <img
                        alt=''
                        srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/HDlmk9tCTQwtYrfV/664a3958-6498-4c63-9970-61523c56825d.jpg?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/HDlmk9tCTQwtYrfV/664a3958-6498-4c63-9970-61523c56825d.jpg?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/HDlmk9tCTQwtYrfV/664a3958-6498-4c63-9970-61523c56825d.jpg?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/HDlmk9tCTQwtYrfV/664a3958-6498-4c63-9970-61523c56825d.jpg?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/HDlmk9tCTQwtYrfV/664a3958-6498-4c63-9970-61523c56825d.jpg?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/HDlmk9tCTQwtYrfV/664a3958-6498-4c63-9970-61523c56825d.jpg?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/HDlmk9tCTQwtYrfV/664a3958-6498-4c63-9970-61523c56825d.jpg?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/HDlmk9tCTQwtYrfV/664a3958-6498-4c63-9970-61523c56825d.jpg?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/HDlmk9tCTQwtYrfV/664a3958-6498-4c63-9970-61523c56825d.jpg?width=3840 3840w'
                        src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/HDlmk9tCTQwtYrfV/664a3958-6498-4c63-9970-61523c56825d.jpg'
                        width={800}
                        height={448}
                        data-instant-type='image'
                        decoding='async'
                        loading='lazy'
                        className='iNbMsYtfkKNl1hbem'
                        data-uw-rm-alt-original=''
                      />
                      <span
                        data-instant-type='text'
                        className='instant-rich-text i1shChxEKnpqPOhxA'>
                        <span>Advanced</span>
                        <span>Heating Elements</span>
                      </span>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text iOZ7kpLTzN4bdxbyR'>
                        <span>
                          Innovative technology provides fast, even cooking
                          without shaking.
                        </span>
                      </span>
                    </div>
                    <div
                      className='i2rXA14fCeSKyBgS1'
                      data-instant-type='container'>
                      <img
                        alt=''
                        srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/mbtkbdmP5h8QJOJM/a84de15f-ef5d-4f76-aecd-379686740de1.jpg?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/mbtkbdmP5h8QJOJM/a84de15f-ef5d-4f76-aecd-379686740de1.jpg?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/mbtkbdmP5h8QJOJM/a84de15f-ef5d-4f76-aecd-379686740de1.jpg?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/mbtkbdmP5h8QJOJM/a84de15f-ef5d-4f76-aecd-379686740de1.jpg?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/mbtkbdmP5h8QJOJM/a84de15f-ef5d-4f76-aecd-379686740de1.jpg?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/mbtkbdmP5h8QJOJM/a84de15f-ef5d-4f76-aecd-379686740de1.jpg?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/mbtkbdmP5h8QJOJM/a84de15f-ef5d-4f76-aecd-379686740de1.jpg?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/mbtkbdmP5h8QJOJM/a84de15f-ef5d-4f76-aecd-379686740de1.jpg?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/mbtkbdmP5h8QJOJM/a84de15f-ef5d-4f76-aecd-379686740de1.jpg?width=3840 3840w'
                        src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/mbtkbdmP5h8QJOJM/a84de15f-ef5d-4f76-aecd-379686740de1.jpg'
                        width={800}
                        height={450}
                        data-instant-type='image'
                        decoding='async'
                        loading='lazy'
                        className='iZGvstigDiVr9ACwO'
                        data-uw-rm-alt-original=''
                      />
                      <span
                        data-instant-type='text'
                        className='instant-rich-text iNPSodN4fbDjFQsEu'>
                        <span>Precision</span>
                        <span>Temperature Control</span>
                      </span>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text iFhSZgd1IGEcMuylv'>
                        <span>
                          Achieve culinary excellence with exact temperature
                          settings for maximum flavor.
                        </span>
                      </span>
                    </div>
                    <div
                      className='iGSoh6bKrb66AW2fO'
                      data-instant-type='container'>
                      <img
                        alt=''
                        srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/TGSvDKQgYmsxfzUb/e0ca3091-9ded-4cb7-b7f9-9c86680e59c3.jpg?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/TGSvDKQgYmsxfzUb/e0ca3091-9ded-4cb7-b7f9-9c86680e59c3.jpg?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/TGSvDKQgYmsxfzUb/e0ca3091-9ded-4cb7-b7f9-9c86680e59c3.jpg?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/TGSvDKQgYmsxfzUb/e0ca3091-9ded-4cb7-b7f9-9c86680e59c3.jpg?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/TGSvDKQgYmsxfzUb/e0ca3091-9ded-4cb7-b7f9-9c86680e59c3.jpg?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/TGSvDKQgYmsxfzUb/e0ca3091-9ded-4cb7-b7f9-9c86680e59c3.jpg?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/TGSvDKQgYmsxfzUb/e0ca3091-9ded-4cb7-b7f9-9c86680e59c3.jpg?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/TGSvDKQgYmsxfzUb/e0ca3091-9ded-4cb7-b7f9-9c86680e59c3.jpg?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/TGSvDKQgYmsxfzUb/e0ca3091-9ded-4cb7-b7f9-9c86680e59c3.jpg?width=3840 3840w'
                        src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/TGSvDKQgYmsxfzUb/e0ca3091-9ded-4cb7-b7f9-9c86680e59c3.jpg'
                        width={800}
                        height={800}
                        data-instant-type='image'
                        decoding='async'
                        loading='lazy'
                        className='ieBZMxlxfpEe2tq1t'
                        data-uw-rm-alt-original=''
                      />
                      <span
                        data-instant-type='text'
                        className='instant-rich-text i2TSoLDEIHbplrt3A'>
                        <span>
                          <strong>
                            Experience the Perfect Crisp, Every Meal
                          </strong>
                        </span>
                      </span>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text i1jo7g7STwaG2cq5R'>
                        <span>
                          Lightning fast cooking and a uniform crispy outcome
                          with every meal.
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='igs47Jh7qv23hVPrs'
                data-instant-type='container'
                id='igs47Jh7qv23hVPrs'>
                <div
                  className='ihZgN40na2Z52dnVa'
                  data-instant-type='container'
                  data-instant-form-product-url='/products/turboblaze-air-fryer'
                  data-instant-form-variant-id='gid://shopify/ProductVariant/47075741335861'>
                  <div
                    className='ik3BXpd3Arl0AL9D7'
                    data-instant-type='container'
                    href=''
                    rel='noopener noreferrer'>
                    <div
                      className='iBHbibvygYvfXQjpB'
                      data-instant-type='container'>
                      <div
                        className='iXHNdFCsJkXXMYSBE'
                        data-instant-type='container'>
                        <div
                          data-instant-type='icon'
                          className='ibVYw7I8SlYW3xfc2'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>star</title>
                            <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                          </svg>
                        </div>
                        <div
                          data-instant-type='icon'
                          className='iUgKTUZ6wJOz0CetO'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>star</title>
                            <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                          </svg>
                        </div>
                        <div
                          data-instant-type='icon'
                          className='iUv6iemwUs7l3JSCh'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>star</title>
                            <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                          </svg>
                        </div>
                        <div
                          data-instant-type='icon'
                          className='inj9UFCR0cYi9OmHM'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>star</title>
                            <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                          </svg>
                        </div>
                        <div
                          data-instant-type='icon'
                          className='iByl1N6qIpwJjO92W'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>star</title>
                            <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                          </svg>
                        </div>
                      </div>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text iaOtRlUlQipS23qsS'>
                        <span>4.8/5.0 — 660+ reviews</span>
                      </span>
                    </div>
                    <h3
                      data-instant-type='text'
                      className='instant-rich-text inrSt0M39H8gUjuc7'>
                      <span>
                        <strong>
                          Ignite Your Kitchen's Potential with TurboBlaze™
                        </strong>
                      </span>
                    </h3>
                    <p
                      data-instant-type='text'
                      className='instant-rich-text iQNG1mREigFxDlJ1W'>
                      <span>
                        Leap into a realm where speed fuses with flavor,
                        crafting meals that please your soul!
                      </span>
                    </p>
                    <div
                      className='if7sKpUbhJWnTP7vX'
                      data-instant-type='container'>
                      <div
                        className='iFn7qqP5rCjxbsopQ'
                        data-instant-type='container'>
                        <div
                          data-instant-type='icon'
                          className='inWYEfKhu7nQXgEyD'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>check-circle</title>
                            <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                          </svg>
                        </div>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text i6CenvUx0FRJaey3n'>
                          <span>9-in-1 Cooking Versatility</span>
                        </span>
                      </div>
                      <div
                        className='ieLHAHJ3t335lGQvC'
                        data-instant-type='container'>
                        <div
                          data-instant-type='icon'
                          className='i4AL2tIgL6IaDap4n'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>check-circle</title>
                            <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                          </svg>
                        </div>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text iuPDVEJrqUSoA1ZWE'>
                          <span>Family Friendly 6.0-Quart Capacity</span>
                        </span>
                      </div>
                      <div
                        className='iUNOOpD3sBnjng2QC'
                        data-instant-type='container'>
                        <div
                          data-instant-type='icon'
                          className='iSwUmeds1BPn1MdnD'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>check-circle</title>
                            <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                          </svg>
                        </div>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text iojk3MhIExWA5ZeOf'>
                          <span>Whisper-Quiet Operation</span>
                        </span>
                      </div>
                      <div
                        className='ipMjbrnTrDpdDooLp'
                        data-instant-type='container'>
                        <div
                          data-instant-type='icon'
                          className='i9TFOjikXf7FigS6d'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>check-circle</title>
                            <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                          </svg>
                        </div>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text iiSZf2GI6bUMB1Tu4'>
                          <span>Sleek Space-Saving Design</span>
                        </span>
                      </div>
                    </div>
                    <div
                      className='iHt4OelhbQE0lcrgv'
                      data-instant-type='container'>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text i9II782RtbylEtK7D'>
                        <span>$96</span>
                      </span>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text igpjO9spbgdABAuYb'>
                        <span>$119.99</span>
                      </span>
                    </div>
                    <span
                      data-instant-type='text'
                      className='instant-rich-text iX7DiRHxadQDwx5dj'>
                      <span>Limited Time Offer!</span>
                    </span>
                    <div
                      className='ifwPymtaPhwXdlOuc'
                      data-instant-type='container'>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text ia28Ycaqogb7Bpzlz'>
                        <span>$96</span>
                      </span>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text iSp9fv40m2Z0Ecz1F'>
                        <span>$119.99</span>
                      </span>
                    </div>
                    <a
                      className='iOShQl4SOPRCF2yZ8'
                      data-instant-type='container'
                      href={stripeLink}
                      rel='noopener noreferrer'>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text iizyX1A3j6qdyII2C'>
                        <span>
                          <strong>Get TurboBlaze for Only $96</strong>
                        </span>
                      </span>
                    </a>
                    <span
                      data-instant-type='text'
                      className='instant-rich-text ikeQQgJ7tP8Ad5ecy'>
                      <span>
                        Get the TurboBlaze™ with <strong>FREE SHIPPING</strong>{' '}
                        — within 7 Business Days.
                      </span>
                    </span>
                  </div>
                  <div
                    className='instant-video__container iJ0mOpKyTDw6VBO42'
                    data-instant-type='video'>
                    <iframe
                      allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                      allowFullScreen=''
                      className='instant-video__iframe'
                      src='https://www.youtube.com/embed/HnRL4awp3UI?v=HnRL4awp3UI&autoplay=1&color=white&state=1&modestbranding=1&loop=1&controls=0&playlist=HnRL4awp3UI&mute=1&playsinline=1'
                      title='NEW TurboBlaze 6.0 QT Air Fryer. Top Rated Air Fryer Oven, by Cosori'
                    />
                    <span aria-hidden='true'>
                      <img
                        className='instant-video__sizer'
                        alt=''
                        aria-hidden='true'
                        src='data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2710000%27%20height=%275650%27/%3e'
                        data-uw-rm-alt-original=''
                      />
                    </span>
                  </div>
                  <img
                    alt=''
                    srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/9oM8eZKMbuVAad31/screenshot-2024-01-20-at-55608pm.png?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/9oM8eZKMbuVAad31/screenshot-2024-01-20-at-55608pm.png?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/9oM8eZKMbuVAad31/screenshot-2024-01-20-at-55608pm.png?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/9oM8eZKMbuVAad31/screenshot-2024-01-20-at-55608pm.png?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/9oM8eZKMbuVAad31/screenshot-2024-01-20-at-55608pm.png?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/9oM8eZKMbuVAad31/screenshot-2024-01-20-at-55608pm.png?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/9oM8eZKMbuVAad31/screenshot-2024-01-20-at-55608pm.png?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/9oM8eZKMbuVAad31/screenshot-2024-01-20-at-55608pm.png?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/9oM8eZKMbuVAad31/screenshot-2024-01-20-at-55608pm.png?width=3840 3840w'
                    src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/9oM8eZKMbuVAad31/screenshot-2024-01-20-at-55608pm.png'
                    width={2034}
                    height={1770}
                    data-instant-type='image'
                    decoding='async'
                    loading='lazy'
                    className='iiMSAuzBQpc5OQLzx'
                  />
                </div>
              </div>
              <div
                className='i5iu5kCLFhRt0L5vt'
                data-instant-type='container'
                id='i5iu5kCLFhRt0L5vt'>
                <div
                  className='iPK8btxKcpTrfujsv'
                  data-instant-type='container'>
                  <div
                    className='ia3uEd89RM4WV58Ag'
                    data-instant-type='container'>
                    <div
                      className='isdE4GYRBbn50x48I'
                      data-instant-type='container'>
                      <div
                        data-instant-type='icon'
                        className='ijljRbN3xxQVPP7fk'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 256 256'
                          className='instant-icon'>
                          <title>lightning</title>
                          <path d='M213.85,125.46l-112,120a8,8,0,0,1-13.69-7l14.66-73.33L45.19,143.49a8,8,0,0,1-3-13l112-120a8,8,0,0,1,13.69,7L153.18,90.9l57.63,21.61a8,8,0,0,1,3,12.95Z' />
                        </svg>
                      </div>
                    </div>
                    <h3
                      data-instant-type='text'
                      className='instant-rich-text iy9kr7ASspMBRluNr'>
                      <span>
                        <strong>
                          Experience Speed &amp; Flavor with TurboBlaze™ Air
                          Fryer
                        </strong>
                      </span>
                    </h3>
                    <span
                      data-instant-type='text'
                      className='instant-rich-text ilD1Up8lwR3zT7M8y'>
                      <span>
                        <strong>
                          TurboBlaze™ Air Fryer: Fast Cooking, Rich Flavors
                        </strong>
                      </span>
                    </span>
                    <div
                      className='iwPlfAAv2IF1qSkLC'
                      data-instant-type='container'>
                      <div
                        className='icHLrwylmk7gkumcd'
                        data-instant-type='container'>
                        <div
                          data-instant-type='icon'
                          className='i9wVBeLKXfksGLneW'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>check-circle</title>
                            <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                          </svg>
                        </div>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text ivD8IuerGt7uPvbG7'>
                          <span>
                            <strong>Cook up to 46% Faster</strong>– Spend less
                            time waiting, more time savoring.
                          </span>
                        </span>
                      </div>
                      <div
                        className='i0oGtTtdPHe9xpBKZ'
                        data-instant-type='container'>
                        <div
                          data-instant-type='icon'
                          className='ikyvet0DYCw3m9UXF'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>check-circle</title>
                            <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                          </svg>
                        </div>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text i3GyAdW2zJtY6zMJI'>
                          <span>
                            <strong>9-in-1 Cooking</strong>– Your go-to for a
                            variety of meals.
                          </span>
                        </span>
                      </div>
                      <div
                        className='i3fVkkUKtgRUPZNv2'
                        data-instant-type='container'>
                        <div
                          data-instant-type='icon'
                          className='iXQPfWoSCFGAOl9so'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>check-circle</title>
                            <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                          </svg>
                        </div>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text ilfSjuOwPBnSX6P46'>
                          <span>
                            <strong>6.0-Quart Capacity</strong>– Perfect for
                            families, cooks for 3-5.
                          </span>
                        </span>
                      </div>
                      <div
                        className='iJgs7TcJDYj7MNNrb'
                        data-instant-type='container'>
                        <div
                          data-instant-type='icon'
                          className='ivZqYPNX7P9kQw30V'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>check-circle</title>
                            <path d='M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z' />
                          </svg>
                        </div>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text im02ITSbkzqkGIA0s'>
                          <span>
                            <strong>Easy Interface</strong>– Smart features for
                            hassle-free cooking.
                          </span>
                        </span>
                      </div>
                    </div>
                    <a
                      className='iozvSyrXOp1cXlMmo'
                      data-instant-type='container'
                      data-instant-form-product-url='/products/turboblaze-air-fryer'
                      data-instant-form-variant-id='gid://shopify/ProductVariant/47075741335861'
                      href={stripeLink}
                      rel='noopener noreferrer'>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text iCzBzBCZkX5VfDhGm'>
                        <span>
                          <strong>Only $96 – buy now</strong>
                        </span>
                      </span>
                    </a>
                  </div>
                  <img
                    alt=''
                    srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/AR0U9jcB0nbhQxIX/cmkt2311turboblazerefresha1square-1.jpg?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/AR0U9jcB0nbhQxIX/cmkt2311turboblazerefresha1square-1.jpg?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/AR0U9jcB0nbhQxIX/cmkt2311turboblazerefresha1square-1.jpg?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/AR0U9jcB0nbhQxIX/cmkt2311turboblazerefresha1square-1.jpg?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/AR0U9jcB0nbhQxIX/cmkt2311turboblazerefresha1square-1.jpg?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/AR0U9jcB0nbhQxIX/cmkt2311turboblazerefresha1square-1.jpg?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/AR0U9jcB0nbhQxIX/cmkt2311turboblazerefresha1square-1.jpg?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/AR0U9jcB0nbhQxIX/cmkt2311turboblazerefresha1square-1.jpg?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/AR0U9jcB0nbhQxIX/cmkt2311turboblazerefresha1square-1.jpg?width=3840 3840w'
                    src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/AR0U9jcB0nbhQxIX/cmkt2311turboblazerefresha1square-1.jpg'
                    width={3000}
                    height={3000}
                    data-instant-type='image'
                    decoding='async'
                    loading='lazy'
                    className='ioVikYqJpOZzfaFh3'
                  />
                </div>
              </div>
              <div
                className='i0yHO6bITbD1tNYMG'
                data-instant-type='container'
                id='i0yHO6bITbD1tNYMG'>
                <div
                  className='iWGrOHsElg4n4AFZ3'
                  data-instant-type='container'
                  data-instant-form-product-url='/products/turboblaze-air-fryer'
                  data-instant-form-variant-id='gid://shopify/ProductVariant/47075741335861'>
                  <span
                    data-instant-type='text'
                    className='instant-rich-text i0GLcotFC9aaezXvQ'>
                    <span>The Next Generation of Air Frying</span>
                  </span>
                  <h3
                    data-instant-type='text'
                    className='instant-rich-text i8ubAGcLf9dujeMJt'>
                    <span>
                      <strong>Build Better Meals in Less Time.</strong>
                    </span>
                  </h3>
                  <span
                    data-instant-type='text'
                    className='instant-rich-text ippyLXPGREJ9cYOmu'>
                    <span>
                      TurboBlaze™ Air Fryer: Swift, Savory, Smart. Revolutionize
                      mealtime with our cutting-edge air fryer, perfect for your
                      on-the-go lifestyle.
                    </span>
                  </span>
                  <div
                    className='iGxOg642ky69dqZyB'
                    data-instant-type='container'>
                    <div
                      className='iLg83WPDJMO6fmdYk'
                      data-instant-type='container'>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text iPmUgqC23Bsagu9pT'>
                        <span>
                          Get the TurboBlaze™ with{' '}
                          <strong>FREE SHIPPING</strong>— within 7 Business
                          Days.
                        </span>
                      </span>
                      <a
                        className='iAKy39agSQ22gII6s'
                        data-instant-type='container'
                        href={stripeLink}
                        rel='noopener noreferrer'>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text iAumClul5TcPo8UhF'>
                          <span>Buy now • 20% off</span>
                        </span>
                      </a>
                    </div>
                    <div
                      className='i7fvKpeSqpGNUxS12'
                      data-instant-type='container'
                    />
                  </div>
                  <div
                    className='iTo8JyETUa0Shf1yO'
                    data-instant-type='container'>
                    <div
                      className='iXrH9gmGQFjPpRslJ'
                      data-instant-type='container'>
                      <img
                        alt=''
                        srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/WTXtkYYgJAiDUe7N/6583388491714369893.png?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/WTXtkYYgJAiDUe7N/6583388491714369893.png?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/WTXtkYYgJAiDUe7N/6583388491714369893.png?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/WTXtkYYgJAiDUe7N/6583388491714369893.png?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/WTXtkYYgJAiDUe7N/6583388491714369893.png?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/WTXtkYYgJAiDUe7N/6583388491714369893.png?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/WTXtkYYgJAiDUe7N/6583388491714369893.png?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/WTXtkYYgJAiDUe7N/6583388491714369893.png?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/WTXtkYYgJAiDUe7N/6583388491714369893.png?width=3840 3840w'
                        src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/WTXtkYYgJAiDUe7N/6583388491714369893.png'
                        width={1200}
                        height={1500}
                        data-instant-type='image'
                        decoding='async'
                        loading='lazy'
                        className='iNb4I27J8i1kO20ze'
                      />
                      <div
                        className='iVMBJuu6My7C7uDEs'
                        data-instant-type='container'>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text iAwn9k53plyMmlEAa'>
                          <span>
                            <strong>1. Fast Cooking</strong>
                          </span>
                        </span>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text ibkT81fmGSerWQvM6'>
                          <span>
                            Cook up to 46% faster with TurboBlaze™. Less
                            waiting, more enjoying.
                          </span>
                        </span>
                      </div>
                    </div>
                    <div
                      className='irRn4lzLbz0U1dgSv'
                      data-instant-type='container'>
                      <img
                        alt=''
                        srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/LgPFOrWgN7enFFm3/6632483056673297704.jpg?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/LgPFOrWgN7enFFm3/6632483056673297704.jpg?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/LgPFOrWgN7enFFm3/6632483056673297704.jpg?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/LgPFOrWgN7enFFm3/6632483056673297704.jpg?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/LgPFOrWgN7enFFm3/6632483056673297704.jpg?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/LgPFOrWgN7enFFm3/6632483056673297704.jpg?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/LgPFOrWgN7enFFm3/6632483056673297704.jpg?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/LgPFOrWgN7enFFm3/6632483056673297704.jpg?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/LgPFOrWgN7enFFm3/6632483056673297704.jpg?width=3840 3840w'
                        src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/LgPFOrWgN7enFFm3/6632483056673297704.jpg'
                        width={1200}
                        height={1200}
                        data-instant-type='image'
                        decoding='async'
                        loading='lazy'
                        className='iU4agqaYKhH8dUbUZ'
                      />
                      <div
                        className='itEs83JHMJcpX1Ivd'
                        data-instant-type='container'>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text iRoRb5552tmeIXqJe'>
                          <span>
                            2.
                            <strong>Culinary Mastery</strong>
                          </span>
                        </span>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text iGrbwr35oiSSwXeWp'>
                          <span>
                            TurboBlaze™ delivers restaurant-quality results.
                            Savor every bite.
                          </span>
                        </span>
                      </div>
                    </div>
                    <div
                      className='ifAukmKAmXLnVwlvM'
                      data-instant-type='container'>
                      <img
                        alt=''
                        srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/r9NXMLbqgzzPR8GZ/1580392282499110048.jpg?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/r9NXMLbqgzzPR8GZ/1580392282499110048.jpg?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/r9NXMLbqgzzPR8GZ/1580392282499110048.jpg?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/r9NXMLbqgzzPR8GZ/1580392282499110048.jpg?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/r9NXMLbqgzzPR8GZ/1580392282499110048.jpg?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/r9NXMLbqgzzPR8GZ/1580392282499110048.jpg?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/r9NXMLbqgzzPR8GZ/1580392282499110048.jpg?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/r9NXMLbqgzzPR8GZ/1580392282499110048.jpg?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/r9NXMLbqgzzPR8GZ/1580392282499110048.jpg?width=3840 3840w'
                        src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/r9NXMLbqgzzPR8GZ/1580392282499110048.jpg'
                        width={1200}
                        height={1200}
                        data-instant-type='image'
                        decoding='async'
                        loading='lazy'
                        className='iuWwVWUPkhchnDNNL'
                      />
                      <div
                        className='iOrlOsAeCGLBvyzGv'
                        data-instant-type='container'>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text iU7xfOivhD5Hu0woa'>
                          <span>
                            3.
                            <strong>Sleek &amp; Roomy</strong>
                          </span>
                        </span>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text i1McApUEyhyfqQPVw'>
                          <span>
                            A modern look with a 6-quart capacity. Big meals
                            without the clutter.
                          </span>
                        </span>
                      </div>
                    </div>
                    <div
                      className='iMxk8SnmPcVUSZrVI'
                      data-instant-type='container'>
                      <img
                        alt=''
                        srcSet='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/jLgf1yhv57Zr7G2q/17985008982113080681.jpg?width=360 360w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/jLgf1yhv57Zr7G2q/17985008982113080681.jpg?width=640 640w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/jLgf1yhv57Zr7G2q/17985008982113080681.jpg?width=750 750w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/jLgf1yhv57Zr7G2q/17985008982113080681.jpg?width=828 828w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/jLgf1yhv57Zr7G2q/17985008982113080681.jpg?width=1080 1080w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/jLgf1yhv57Zr7G2q/17985008982113080681.jpg?width=1200 1200w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/jLgf1yhv57Zr7G2q/17985008982113080681.jpg?width=1920 1920w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/jLgf1yhv57Zr7G2q/17985008982113080681.jpg?width=2048 2048w, https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/jLgf1yhv57Zr7G2q/17985008982113080681.jpg?width=3840 3840w'
                        src='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/jLgf1yhv57Zr7G2q/17985008982113080681.jpg'
                        width={960}
                        height={1200}
                        data-instant-type='image'
                        decoding='async'
                        loading='lazy'
                        className='ifQPZKxVGqRROCg0H'
                      />
                      <div
                        className='il5d7fDpN1U4mFpDX'
                        data-instant-type='container'>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text iN9oknPRjAlUd676O'>
                          <span>
                            4.
                            <strong>Whisper-Quiet</strong>
                          </span>
                        </span>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text iWHECpa6FB6x13u02'>
                          <span>
                            TurboBlaze™ ensures a calm cooking ambiance at a
                            whisper-level.
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='ibWiYx66W9pNkCNIb'
                data-instant-type='container'
                id='ibWiYx66W9pNkCNIb'>
                <div
                  className='iL6UcxyFdpek439s4'
                  data-instant-type='container'>
                  <div
                    className='iqVTMMrBYJRLI8Bcl'
                    data-instant-type='container'>
                    <div
                      className='irU3Lkm5eMdB60K1F'
                      data-instant-type='container'>
                      <h3
                        data-instant-type='text'
                        className='instant-rich-text iDzNuhC3N3eOBcz9v'>
                        <span>Get Frying</span>
                      </h3>
                      <div
                        className='ic6cuhHAcUuRZYfWC'
                        data-instant-type='container'>
                        <div
                          data-instant-type='icon'
                          className='iQnOfWvPgMxQiHRrx'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>star</title>
                            <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                          </svg>
                        </div>
                        <div
                          data-instant-type='icon'
                          className='iwRILUWcwhljTGsVr'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>star</title>
                            <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                          </svg>
                        </div>
                        <div
                          data-instant-type='icon'
                          className='iLgOa8tUDgqwhCGCJ'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>star</title>
                            <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                          </svg>
                        </div>
                        <div
                          data-instant-type='icon'
                          className='i9WSqFUb1H2WlVQ75'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>star</title>
                            <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                          </svg>
                        </div>
                        <div
                          data-instant-type='icon'
                          className='iTBGEd6q67UIi0eSo'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            viewBox='0 0 256 256'
                            className='instant-icon'>
                            <title>star</title>
                            <path d='M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z' />
                          </svg>
                        </div>
                      </div>
                      <div
                        className='ivRADVOmz4RjOKQZP'
                        data-instant-type='container'>
                        <img
                          alt=''
                          src='https://cdn.instant.so/static/templates/assets/wave-star.svg'
                          data-instant-type='image'
                          decoding='async'
                          loading='lazy'
                          className='isVAIHtr6q3nq15YF'
                        />
                        <img
                          alt=''
                          src='https://cdn.instant.so/static/templates/assets/wave-star.svg'
                          data-instant-type='image'
                          decoding='async'
                          loading='lazy'
                          className='i8NP9ZVp8zTXMr1pC'
                        />
                        <img
                          alt=''
                          src='https://cdn.instant.so/static/templates/assets/wave-star.svg'
                          data-instant-type='image'
                          decoding='async'
                          loading='lazy'
                          className='iHevmKTHMMyH41Ixc'
                        />
                        <img
                          alt=''
                          src='https://cdn.instant.so/static/templates/assets/wave-star.svg'
                          data-instant-type='image'
                          decoding='async'
                          loading='lazy'
                          className='i8i2PEYYx7QwV7aio'
                        />
                        <img
                          alt=''
                          src='https://cdn.instant.so/static/templates/assets/wave-star.svg'
                          data-instant-type='image'
                          decoding='async'
                          loading='lazy'
                          className='iGZdOv1qlyNsdfTkB'
                        />
                      </div>
                      <div
                        className='ipByhh1ozT7ICoMDh'
                        data-instant-type='container'>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text ioEXEL1sAuEXOd3Dz'>
                          <span>500+ Hot Reviews</span>
                        </span>
                      </div>
                      <p
                        data-instant-type='text'
                        className='instant-rich-text irOG6E8wV1TLxh05N'>
                        <span>
                          Enjoy the art of cooking with the TurboBlaze Air
                          Fryer.
                        </span>
                        <span />
                        <span>
                          Get the TurboBlaze™ with{' '}
                          <strong>FREE SHIPPING</strong>— within 7 Business
                          Days.
                        </span>
                      </p>
                      <div
                        className='iE82SennnMZW4l1gp'
                        data-instant-type='container'>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text i8OlM3I3Uuw4wecyp'>
                          <span>$119.99</span>
                        </span>
                        <span
                          data-instant-type='text'
                          className='instant-rich-text i6hSMfeijNAySo4Tb'>
                          <span>$96</span>
                        </span>
                      </div>
                      <div
                        className='iZXEsYS3g811cyTrE'
                        data-instant-type='container'
                        data-instant-form-product-url='/products/turboblaze-air-fryer'
                        data-instant-form-variant-id='gid://shopify/ProductVariant/47075741335861'
                        href={stripeLink}
                        rel='noopener noreferrer'>
                        <a
                          data-instant-type='text'
                          className='iU8O8VnNc78eXDdhu'
                          href='/cart/47075741335861:1'
                          rel='noopener noreferrer'
                          data-instant-action-type='redirect-to-checkout'
                          data-instant-action-id={8793296634165}
                          data-instant-action-variant-id={47075741335861}>
                          <span className='instant-rich-text'>
                            <span>Buy now • 20% off</span>
                          </span>
                        </a>
                      </div>
                      <span
                        data-instant-type='text'
                        className='instant-rich-text i83gJIPgRH6YKZVLN'>
                        <span>Free shipping on all orders!</span>
                      </span>
                    </div>
                  </div>
                  <div
                    className='ioBSc4rkCMB8SvUFt'
                    data-instant-type='container'>
                    <div
                      data-paused='false'
                      className='instant-video-fill__wrapper'>
                      <div className='instant-video__fallback instant-media__fallback'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width={48}
                          height={48}
                          fill='rgba(255, 255, 255, 1)'
                          viewBox='0 0 256 256'
                          className='instant-video-fill__pause'>
                          <path d='M232,208a8,8,0,0,1-8,8H32a8,8,0,0,1,0-16H224A8,8,0,0,1,232,208Zm0-152V168a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56Zm-68,56a8,8,0,0,0-3.71-6.75l-44-28A8,8,0,0,0,104,84v56a8,8,0,0,0,12.29,6.75l44-28A8,8,0,0,0,164,112Z' />
                        </svg>
                      </div>
                      <video
                        poster='https://cdn.instant.so/sites/dumEsNefpCLLZuwN/assets/4fZs08KoHnafaHiX/cgtm2303caf-dc601-kuswa101frontv2.png'
                        preload='metadata'
                        src='https://cdn.shopify.com/videos/c/vp/c0c8588fa0b74490b586b07e9796a7c1/c0c8588fa0b74490b586b07e9796a7c1.HD-1080p-7.2Mbps-19143694.mp4'
                        playsInline=''
                        autoPlay=''
                        muted=''
                        loop=''
                        className='instant-video-fill__video'
                      />
                      <div className='instant-video-fill__overlay' />
                      <button
                        className='instant-video-fill__controls'
                        type='button'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width={26}
                          height={26}
                          fill='rgba(0, 0, 0, 1)'
                          viewBox='0 0 256 256'
                          className='instant-video-fill__play'>
                          <path d='M216,48V208a16,16,0,0,1-16,16H160a16,16,0,0,1-16-16V48a16,16,0,0,1,16-16h40A16,16,0,0,1,216,48ZM96,32H56A16,16,0,0,0,40,48V208a16,16,0,0,0,16,16H96a16,16,0,0,0,16-16V48A16,16,0,0,0,96,32Z' />
                        </svg>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width={26}
                          height={26}
                          fill='rgba(0, 0, 0, 1)'
                          viewBox='0 0 256 256'
                          className='instant-video-fill__pause'>
                          <path d='M240,128a15.74,15.74,0,0,1-7.6,13.51L88.32,229.65a16,16,0,0,1-16.2.3A15.86,15.86,0,0,1,64,216.13V39.87a15.86,15.86,0,0,1,8.12-13.82,16,16,0,0,1,16.2.3L232.4,114.49A15.74,15.74,0,0,1,240,128Z' />
                        </svg>
                      </button>
                    </div>
                    <span
                      data-instant-type='text'
                      className='instant-rich-text i9EJ600L3W5mTS77b'>
                      <span>20% OFF</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* prettier-ignore */}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;
