import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import Home from './Home';
import Success from './Success';

const Routes = () => {
  return (
    <Switch>
      <Route
        path='/'
        exact
        render={(props) => {
          return <Home {...props} />;
        }}
      />
      <Route
        path='/success'
        exact
        render={(props) => {
          return <Success {...props} />;
        }}
      />
    </Switch>
  );
};

export default Routes;
