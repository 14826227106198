import { Link } from 'react-router-dom';
import './success.css';
const Success = () => {
  return (
    <div>
      <section
        id='shopify-section-template--20583045169461__main'
        className='shopify-section'>
        <style
          data-shopify=''
          dangerouslySetInnerHTML={{
            __html:
              '\n  #shopify-section-template--20583045169461__main {\n    \n      background-color: #f2f2f2;\n    \n    padding-top: 4rem;\n    padding-bottom: 4rem;\n  }\n\n  #Main404-Title-template--20583045169461__main {\n    \n      color: #005e6e;\n    \n  }\n  #Main404-Desc-template--20583045169461__main {\n    \n      color: #000000;\n    \n  }\n  @media (min-width: 1024px) {\n    #shopify-section-template--20583045169461__main {\n      padding-top: 4rem;\n      padding-bottom: 4rem;\n    }\n  }\n',
          }}
        />
        <div className='container'>
          <h1
            id='Main404-Title-template--20583045169461__main'
            className='text-5xl md:text-5xl lg:text-9xl uppercase font-black'>
            Thank you <br />
            for your order
          </h1>
          <ul className='grid lg:grid-cols-2 gap-12'>
            <li className=''>
              <div className='aspect-square'>
                <figure className='relative  group overflow-hidden w-full h-full'>
                  <lazy-image className='block h-full w-full overflow-hidden fade-in'>
                    <img
                      src='//cosori.com/cdn/shop/files/404-cosori.png?v=1685641275&width=992'
                      alt='-'
                      width={600}
                      height={800}
                      data-src='//cosori.com/cdn/shop/files/404-cosori.png?v=1685641275&width=992'
                      loading='lazy'
                      srcSet='//cosori.com/cdn/shop/files/404-cosori.png?v=1685641275&width=768 320w, 
    //cosori.com/cdn/shop/files/404-cosori.png?v=1685641275&width=992 768w,
    //cosori.com/cdn/shop/files/404-cosori.png?v=1685641275&width=992 1024w'
                      className='absolute inset-0 object-contain h-full w-full  '
                      data-uw-rm-alt-original=' - '
                      data-uw-rm-alt='BE'
                    />
                  </lazy-image>
                </figure>
              </div>
            </li>
            <li className=''>
              <p
                id='Main404-Desc-template--20583045169461__main'
                className='text-xl lg:text-3xl mb-12 mt-12'>
                We received your order. <br />
                We are doing our best to ship our <br />
                products within 7 business days.
              </p>
              <img
                src='/success.png'
                alt='success'
                style={{
                  maxWidth: '150px',
                  marginBottom: '42px',
                  margin: '0 auto 42px',
                }}
              />
              <Link
                to='/'
                style={{
                  fontSize: '24px',
                  fontWeight: '300',
                  textAlign: 'center',
                  width: 'fit-content',
                  display: 'flex',
                }}>
                Back Home
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default Success;
